import React from 'react';
import '../styles/Footer.css';
// Optional: Import any required CSS or UI components

const Footer = () => {
    return (
      <footer className="footer">
        <a href="https://www.instagram.com/highertv" target="_blank" rel="noopener noreferrer">INSTAGRAM</a>
        <a href="https://www.youtube.com/@higher-tv" target="_blank" rel="noopener noreferrer">YOUTUBE</a>
        <a href="https://soundcloud.com/highertv" target="_blank" rel="noopener noreferrer">SOUNDCLOUD</a>
      </footer>
    );
  };
  
  export default Footer;
