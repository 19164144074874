import React from 'react';
// import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import logoSrc from '../assets/higher-logo.svg';
import SoundCloudPlayer from '../components/SoundCloudPlayer';
import '../styles/index.css'; // Adjust the path if necessary

const Home = () => {
  return (
    <div className="mainContainer">
      <Header />
      {/* <Navbar /> */}
      <main>
        <img src={logoSrc} className="logo" alt="Logo" />
        <SoundCloudPlayer />
      </main>
      <Footer />
    </div>
  );
};

export default Home;
