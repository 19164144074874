import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/index';
import StudioBooking from './pages/StudioBooking'; // Adjust the import path as necessary
import Artists from './pages/Artists'; // Import the Artist page
import './components/firebase-config';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} /> {/* Home page */}
        <Route path="/studio" element={<StudioBooking />} /> {/* Studio booking page */}
        <Route path="/artists" element={<Artists />} /> {/* Artist page */}
      </Routes>
    </Router>
  );
}

export default App;
