import React, { useState } from 'react';
import BookingForm from './BookingForm'; // Assume you have this component
// If you're using a library for date selection, import it here

const StudioBooking = () => {
  const [selectedDate, setSelectedDate] = useState(''); // For the selected date

  // Function to handle date change
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  // Submit handler for the booking form
  const handleSubmit = (bookingDetails) => {
    console.log('Booking Details:', bookingDetails);
    // Here you would typically send the booking details to your backend server
  };

  return (
    <div>
      <h2>Book Studio Time</h2>
      <div>
        <label htmlFor="bookingDate">Select a Date:</label>
        <input
          type="date"
          id="bookingDate"
          name="bookingDate"
          value={selectedDate}
          onChange={handleDateChange}
        />
      </div>
      {selectedDate && <BookingForm onSubmit={handleSubmit} selectedDate={selectedDate} />}
    </div>
  );
};

export default StudioBooking;
