// src/components/Header/Header.js
import React from 'react';
import '../styles/Header.css'; // Ensure you create this CSS file

const Header = () => {
    return (
      <header className="header">
        <a href="/studio" target="_blank" rel="noopener noreferrer">STUDIO</a>
        <a href="/artists" target="_blank" rel="noopener noreferrer">ARTISTS</a>
        {/* Add any other links or site navigation elements here */}
      </header>
    );
};

export default Header;
