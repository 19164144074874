// src/pages/Artists.js
import React from 'react';
import { Layout, Row, Col, Typography, Button, Card } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import '../styles/Artists.css'; // Ensure you create this CSS file
import GOHImage from '../assets/GOH.jpg'; // Import the image file

const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;

const Artist = () => {
    const currentYear = new Date().getFullYear(); // Get the current year

    return (
        <Layout className="layout">
            <Header>
                <div className="logo" />
                <a href="/">Home</a>
            </Header>
            <Content style={{ padding: '0 50px' }}>
                <div className="site-layout-content">
                    <Row justify="center" align="middle" style={{ minHeight: '80vh' }}>
                        <Col span={12}>
                            <Card
                                cover={<img alt="GOH" src={GOHImage} />} // Use the imported image
                                actions={[
                                    <Button type="primary" icon={<MailOutlined />}>
                                        Book Now
                                    </Button>,
                                ]}
                            >
                                <Title level={2}>GOH</Title>
                                <Paragraph>
                                    GOH is an exceptional artist known for his unique style and captivating performances. With a deep passion for music and an innovative approach to his art, GOH has mesmerized audiences worldwide.
                                </Paragraph>
                                <Paragraph>
                                    For booking inquiries, please contact us via email or click the button below.
                                </Paragraph>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
                GOH ©{currentYear} Created by GOH.io | Site designed by <a href="https://phi.llc" target="_blank" rel="noopener noreferrer">PHI</a>
            </Footer>
        </Layout>
    );
};

export default Artist;
