import React from 'react';

const SoundCloudPlayer = () => {
  const iframeSrc = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1774041144&color=%23a6a6a6&auto_play=false&hide_related=true&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true";
  const soundCloudLinksHTML = {
    __html: `<div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/highertv" title="Higher." target="_blank" style="color: #cccccc; text-decoration: none;">Higher.</a> · <a href="https://soundcloud.com/highertv/goh-set-1" title="3.2.24 - GOH @ Make Believe" target="_blank" style="color: #cccccc; text-decoration: none;">3.2.24 - GOH @ Make Believe</a></div>`
  };

  return (
    <div>
      <iframe 
        title="GOH @ Make Believe - 3.2.24"
        width="100%" 
        height="300" 
        color="000"
        scrolling="no" 
        frameborder="no"
        allow="autoplay" 
        src={iframeSrc}>
      </iframe>
      <div dangerouslySetInnerHTML={soundCloudLinksHTML} />
    </div>
  );
};

export default SoundCloudPlayer;
